import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    username: "",
    userid: null,
    entityid: null,
    roomRoleInfo: null,
    balance: 0,
    roomlist: [],
    network: true,
  },
  getters: {
    rooms(state) {
      const roomsObj = {};
      for (let i = 0; i < state.roomlist.length; i++) {
        roomsObj[state.roomlist[i].id] = state.roomlist[i];
      }
      return roomsObj;
    },
    getRoomById(state) {
      return (id) => {
        return state.roomlist.find((room) => room.id == id);
      };
    },
    joinedRooms(state) {
      return state.roomlist.filter((room) => room.Entity_Id != -1);
    },
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token;
    },
    setUsername: (state, username) => {
      state.username = username;
    },
    setUserId: (state, userid) => {
      state.userid = userid;
    },
    setEntityId: (state, entityid) => {
      state.entityid = entityid;
    },
    setRoomRoleInfo: (state, roomRoleInfo) => {
      state.roomRoleInfo = roomRoleInfo;
    },
    setBalance: (state, balance) => {
      state.balance = balance;
    },
    setRoomList: (state, roomlist) => {
      state.roomlist = roomlist;
    },
    changeNetwork: (state, network) => {
      state.network = network;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
