import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/user/LoginView.vue";
import store from "../store";

Vue.use(VueRouter);

const noAuthList = ["/user/login", "/user/register"];

const routes = [
  {
    path: "/user/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/user/register",
    name: "register",
    component: () => import("../views/user/RegisterView.vue"),
    meta: {
      title: "注册",
    },
  },
  {
    path: "/",
    component: () => import("../views/home/HomeView.vue"),
    meta: {
      title: "首页",
    },
    children: [
      {
        path: "",
        name: "sandbox-list",
        component: () => import("../views/home/SandboxListView.vue"),
        meta: {
          title: "沙盘列表",
        },
      },
      {
        path: "join-list",
        name: "joined-sandbox",
        component: () => import("../views/home/JoinedSandboxView.vue"),
        meta: {
          title: "已加入沙盘",
        },
      },
      {
        path: "tutorial",
        name: "tutorial",
        component: () => import("../views/home/TutorialView.vue"),
        meta: {
          title: "学习交易流程",
        },
      },
    ],
  },
  {
    path: "/admin",
    component: () => import("../views/admin/AdminView.vue"),
    meta: {
      title: "系统管理",
    },
    children: [
      {
        path: "",
        name: "sandbox",
        component: () => import("../views/admin/SandboxView.vue"),
        meta: {
          title: "沙盘管理",
        },
      },
      {
        path: "student",
        name: "student",
        component: () => import("../views/admin/StudentView.vue"),
        meta: {
          title: "学员管理",
        },
      },
      {
        path: "teacher",
        name: "teacher",
        component: () => import("../views/admin/TeacherView.vue"),
        meta: {
          title: "教师管理",
        },
      },
    ],
  },
  {
    path: "/sandbox/:id",
    component: () => import("../views/sandbox/SandboxView.vue"),
    children: [
      {
        path: "select",
        name: "sandbox-select-role",
        component: () => import("../views/sandbox/SelectRoleView.vue"),
        meta: {
          title: "选择角色",
        },
      },
      {
        path: "create/:role",
        name: "sandbox-create-role",
        component: () => import("../views/sandbox/CreateRoleView.vue"),
        meta: {
          title: "创建角色",
        },
      },
      {
        path: "console",
        name: "sandbox-console",
        component: () => import("../views/sandbox/ConsoleView.vue"),
        meta: {
          title: "控制台",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (noAuthList.indexOf(to.path) !== -1) {
    next();
  } else {
    const token = store.state.token;
    if (!token) {
      next("/user/login");
    } else {
      next();
    }
  }
});

export default router;
