<template>
  <div id="LoginForm" style="z-index: 999">
    <el-form
      class="userForm"
      :model="ruleForm"
      :rules="rules"
      ref="LoginForm"
      @keyup.enter.native="submit"
    >
      <el-form-item label="用户名" prop="username">
        <el-input
          type="text"
          v-model="ruleForm.username"
          autocomplete="on"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          type="password"
          v-model="ruleForm.password"
          autocomplete="on"
          clearable
        ></el-input>
      </el-form-item>
      <div class="formButtons">
        <GradientButton @click="login">登录</GradientButton>
        <el-button type="text" @click="$router.push('register')"
          >新用户注册</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import GradientButton from "@/components/common/GradientButton.vue";
export default {
  name: "LoginForm",
  components: {
    GradientButton,
  },
  data() {
    var validateUsername = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      }
      callback();
    };
    return {
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [{ validator: validateUsername, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
      },
    };
  },
  methods: {
    async login() {
      this.$refs["LoginForm"].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            target: "#LoginForm",
            text: "正在登录",
            fullscreen: false,
            background: "rgba(255,255,255, 0.8)",
            customClass: "form-loading",
          });
          const resp = await this.$api.account.user_login(
            this.ruleForm.username,
            this.ruleForm.password
          );
          if (resp.data.code !== 200) {
            this.$message.error("登录失败: " + resp.data.msg);
          } else {
            // commit username
            this.$store.commit("setUsername", this.ruleForm.username);
            this.$store.commit("setToken", resp.data.token);
            this.$store.commit("setUserId", resp.data.User_Id);
            this.$message.success("登录成功，正在跳转至首页");
            setTimeout(() => {
              this.$router.replace({
                path: "/",
              });
            }, 1000);
          }
          loading.close();
        } else {
          this.$message.error("请检查用户名和密码输入");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./css/form.scss";
</style>
