/**
 * 沙盘环境功能接口 - financial
 */
import instance from "@/utils/http"; // 导入http中创建的axios实例

const endpoint = "/ruleengine/financial/";

const financial = {
  // 合同结算测试接口
  contract_transaction() {
    return instance.post(endpoint, {
      action: "contract_transaction",
    });
  },
  // 项目交付测试接口
  project_transaction() {
    return instance.post(endpoint, {
      action: "project_transaction",
    });
  },
  /**
   * 查询剩余可分配能力点
   * @param {Number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>}
   */
  search_points_cached_ability(Entity_Id) {
    return instance.post(endpoint, {
      action: "search_points",
      subaction: "cached_ability",
      Entity_Id,
    });
  },
  /**
   * 查询剩余的行动点
   * @param {Number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>}
   */
  search_points_cached_movement(Entity_Id) {
    return instance.post(endpoint, {
      action: "search_points",
      subaction: "cached_movement",
      Entity_Id,
    });
  },
  /**
   * 能力点分配
   * @param {Object} params 参数字典
   * @returns {Promise<AxiosResponse<any, any>>}
   */
  assign_points(params) {
    return instance.post(endpoint, {
      action: "assign_points",
      Entity_Id: params.Entity_Id,
      Store_Tech: params.Store_Tech,
      Analyze_Tech: params.Analyze_Tech,
      Security_Tech: params.Security_Tech,
      Modeling_Tech: params.Modeling_Tech,
      Certification_Ability: params.Certification_Ability,
      Trade_Ability: params.Trade_Ability,
      Design_Ability: params.Design_Ability,
    });
  },
  /**
   * 购买能力点
   * @param {Number} Entity_Id 角色ID
   * @param {Number} Num_Ability 购买能力点数量
   * @returns {Promise<AxiosResponse<any, any>>}
   */
  ability_transaction(Entity_Id, Num_Ability) {
    return instance.post(endpoint, {
      action: "ability_transaction",
      Entity_Id,
      Num_Ability,
    });
  },
  /**
   * 购买行动点
   * @param {Number} Entity_Id 角色ID
   * @param {Number} Num_Movement 购买行动点数量
   * @returns {Promise<AxiosResponse<any, any>>}
   */
  movement_transaction(Entity_Id, Num_Movement) {
    return instance.post(endpoint, {
      action: "movement_transaction",
      Entity_Id,
      Num_Movement,
    });
  },
  /**
   * 下一回合
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>}
   */
  next_round(Entity_Id) {
    return instance.post(endpoint, {
      action: "next_round",
      Entity_Id,
    });
  },
};

export default financial;
