import Vue from "vue";
import ElementUI from "element-ui";
import Fragment from "vue-fragment";
import moment from "moment";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./api";

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$moment = moment;
Vue.prototype.$roleNameToType = (roleName) => {
  switch (roleName) {
    case "enterprise":
      return 1;
    case "shushang":
      return 2;
    case "third-party":
      return 3;
    case "exchange":
      return 4;
  }
};
Vue.prototype.$roleTypeToName = (roleType) => {
  switch (roleType) {
    case 1:
      return "enterprise";
    case 2:
      return "shushang";
    case 3:
      return "third-party";
    case 4:
      return "exchange";
  }
};

Vue.use(ElementUI);
Vue.use(Fragment.Plugin);

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this; //安装全局事件总线
  },
}).$mount("#app");

export default app;
