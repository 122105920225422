<template>
  <div class="LoginBackground">
    <img class="logoImg" src="@/assets/logo.png" />
    <slot></slot>
    <img class="footerImg" src="@/assets/login-bg.png" />
  </div>
</template>

<script>
export default {
  name: "LoginBackground",
};
</script>

<style lang="scss" scoped>
.LoginBackground {
  width: 100vw;
  height: 100vh;
  padding-top: 90px;
  background: linear-gradient(180deg, #1a1828 0%, #20283e 98%);
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
}
.footerImg {
  width: 900px;
  height: 900px;
  position: fixed;
  left: -205px;
  bottom: -225px;
  user-select: none;
}
</style>
