/**
 * 沙盘环境功能接口 - financial
 */
import instance from "@/utils/http"; // 导入http中创建的axios实例

const endpoint = "/common/resource/";

const resource = {
  /**
   * 查询数据资源
   * @param {number} Room_Id 房间ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  list_data(Room_Id) {
    return instance.post(endpoint, {
      action: "list_data",
      Room_Id,
    });
  },
  /**
   * 更新数据资源
   * @param {number} Data_Id 数据ID
   * @param {object} newdata 新数据
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  modify_data(Data_Id, newdata) {
    return instance.post(endpoint, {
      action: "modify_data",
      Data_Id,
      newdata,
    });
  },
};

export default resource;
