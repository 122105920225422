/**
 * 沙盘环境功能接口 - financial
 */
import instance from "@/utils/http"; // 导入http中创建的axios实例

const endpoint = "/player/control/";

const control = {
  /**
   * 角色创建
   * @param {number} User_Id 用户ID
   * @param {number} Room_Id 房间ID
   * @param {string} Name 沙盘名称
   * @param {string} Field 所属领域
   * @param {string} Legal_Person 主体法人
   * @param {number} Type 主体类型
   * @param {string} Description 描述
   * @param {object} abilityPoint 能力点分配
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  create_character(
    User_Id,
    Room_Id,
    Name,
    Field,
    Legal_Person,
    Type,
    Description,
    abilityPoint
  ) {
    return instance.post(endpoint, {
      action: "create_character",
      User_Id,
      Room_Id,
      Name,
      Field,
      Legal_Person,
      Type,
      Description,
      Store_Tech: abilityPoint.Store_Tech,
      Analyze_Tech: abilityPoint.Analyze_Tech,
      Security_Tech: abilityPoint.Security_Tech,
      Modeling_Tech: abilityPoint.Modeling_Tech,
      Certification_Ability: abilityPoint.Certification_Ability,
      Trade_Ability: abilityPoint.Trade_Ability,
      Design_Ability: abilityPoint.Design_Ability,
    });
  },
  /**
   * 角色信息查询 （查询自身信息）
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  view_entity_view_self(Entity_Id) {
    return instance.post(endpoint, {
      action: "view_entity",
      subaction: "view_self",
      Entity_Id,
    });
  },
  /**
   * 角色信息查询（查询一类主体信息）
   * @param {number} Room_Id 房间ID
   * @param {number} Type 主体类型：1 企业 2. 数商 3第三方 4 .交易所 5.监管
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  view_entity_view_all(Room_Id, Type) {
    return instance.post(endpoint, {
      action: "view_entity",
      subaction: "view_all",
      Room_Id,
      Type,
    });
  },
  /**
   * 资质认证申请
   * @param {number} Send_From 信息来源
   * @param {number} Send_To 信息去向
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  register_entity_qualification_registration(Send_From, Send_To) {
    return instance.post(endpoint, {
      action: "register_entity",
      subaction: "qualification_registration",
      Send_From,
      Send_To,
    });
  },
  /**
   * 交易所开户申请
   * @param {number} Room_Id 房间ID
   * @param {number} Send_From 信息来源
   * @param {number} Send_To 信息去向
   * @param {string} Details 信息详情
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  register_entity_account_registration(
    Room_Id,
    Send_From,
    Send_To,
    Details = "交易所开户申请"
  ) {
    return instance.post(endpoint, {
      action: "register_entity",
      subaction: "account_registration",
      Room_Id,
      Send_From,
      Send_To,
      Details,
    });
  },
  /**
   * 查询审批单(资质认证申请)
   * @param {number} Room_Id 房间ID
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  query_request_qualification_registration(Room_Id, Entity_Id) {
    return instance.post(endpoint, {
      action: "query_request",
      subaction: "qualification_registration",
      Room_Id,
      Entity_Id,
    });
  },
  /**
   * 查询审批单(交易所开户申请)
   * @param {number} Room_Id 房间ID
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  query_request_account_registration(Room_Id, Entity_Id) {
    return instance.post(endpoint, {
      action: "query_request",
      subaction: "account_registration",
      Room_Id,
      Entity_Id,
    });
  },
  /**
   * 受理审批单（资质认证申请）
   * @param {number} Record_Id 信息ID
   * @param {number} Entity_Id 主体ID
   * @param {number} Opinion 受理意见
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  verify_entity_qualification_registration(Record_Id, Entity_Id, Opinion) {
    return instance.post(endpoint, {
      action: "verify_entity",
      subaction: "qualification_registration",
      Record_Id,
      Entity_Id,
      Opinion,
    });
  },
  /**
   * 受理审批单（交易所开户申请）
   * @param {number} Record_Id 信息ID
   * @param {number} Entity_Id 主体ID
   * @param {number} Opinion 受理意见
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  verify_entity_account_registration(Record_Id, Entity_Id, Opinion = 1) {
    return instance.post(endpoint, {
      action: "verify_entity",
      subaction: "account_registration",
      Record_Id,
      Entity_Id,
      Opinion,
    });
  },
  /**
   * 资源查看（数据资源）
   * @param {number} Room_Id 房间ID
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  view_resource_view_data_source(Room_Id, Entity_Id) {
    return instance.post(endpoint, {
      action: "view_resource",
      subaction: "view_data_source",
      Room_Id,
      Entity_Id,
    });
  },
  /**
   * 资源查看（数据资源（已持有））
   * @param {Number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  view_resource_view_data_source_owned(Entity_Id) {
    return instance.post(endpoint, {
      action: "view_resource",
      subaction: "view_data_source_owned",
      Entity_Id,
    });
  },
  /**
   * 资源查看（项目资源）
   * @param {number} Room_Id 房间ID
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  view_resource_view_project(Room_Id, Entity_Id) {
    return instance.post(endpoint, {
      action: "view_resource",
      subaction: "view_project",
      Room_Id,
      Entity_Id,
    });
  },
  /**
   * 资源查看 (项目资源（已持有）)
   * @param {Number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  view_resource_view_project_owned(Entity_Id) {
    return instance.post(endpoint, {
      action: "view_resource",
      subaction: "view_project_owned",
      Entity_Id,
    });
  },
  /**
   * 资源查看（项目需求分析）
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  view_resource_view_demand_analyze(Entity_Id) {
    return instance.post(endpoint, {
      action: "view_resource",
      subaction: "view_demand_analyze",
      Entity_Id,
    });
  },
  /**
   * 资源查看（产品资源）
   * @param {number} Record_Id 信息ID
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  view_resource_view_products(Record_Id) {
    return instance.post(endpoint, {
      action: "view_resource",
      subaction: "view_products",
      Record_Id,
    });
  },
  /**
   * 获取资源（数据资源）
   * @param {number} Entity_Id 主体ID
   * @param {number} Resource_Id 资源ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  obtain_resource_obtain_data(Entity_Id, Resource_Id) {
    return instance.post(endpoint, {
      action: "obtain_resource",
      subaction: "obtain_data",
      Entity_Id,
      Resource_Id,
    });
  },
  /**
   * 获取资源（项目资源）
   * @param {number} Entity_Id 主体ID
   * @param {number} Resource_Id 资源ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  obtain_resource_obtain_project(Entity_Id, Resource_Id) {
    return instance.post(endpoint, {
      action: "obtain_resource",
      subaction: "obtain_project",
      Entity_Id,
      Resource_Id,
    });
  },
  /**
   * 资源注册申请 (数据资源保荐请求)
   * @param {number} Room_Id 房间ID
   * @param {number} Send_From 信息来源
   * @param {number} Send_To 信息去向
   * @param {number} Attachment 附件
   * @param {string} Details 信息详情
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  register_resource_data_recommandation(
    Room_Id,
    Send_From,
    Send_To,
    Attachment,
    Details
  ) {
    return instance.post(endpoint, {
      action: "register_resource",
      subaction: "data_recommandation",
      Room_Id,
      Send_From,
      Send_To,
      Attachment,
      Details,
    });
  },
  /**
   * 资源注册申请 (数据资源评估请求)
   * @param {number} Send_From 信息来源
   * @param {number} Send_To 信息去向
   * @param {number} Attachment 附件
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  register_resource_data_verification(Send_From, Send_To, Attachment) {
    return instance.post(endpoint, {
      action: "register_resource",
      subaction: "data_verification",
      Send_From,
      Send_To,
      Attachment,
      Details: "数据资源评估请求",
    });
  },
  /**
   * 查询审批单 (数据资源保荐请求)
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  query_request_data_recommandation(Entity_Id) {
    return instance.post(endpoint, {
      action: "query_request",
      subaction: "data_recommandation",
      Entity_Id,
    });
  },
  /**
   * 查询审批单 (数据资源评估请求)
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  query_request_data_verification(Entity_Id) {
    return instance.post(endpoint, {
      action: "query_request",
      subaction: "data_verification",
      Entity_Id,
    });
  },
  /**
   * 查询审批单 (数据产品评估请求)
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  query_request_product_verification(Entity_Id) {
    return instance.post(endpoint, {
      action: "query_request",
      subaction: "product_verification",
      Entity_Id,
    });
  },
  /**
   * 受理审批单（数据资源保荐请求）
   * @param {number} Record_Id
   * @param {number} Entity_Id 主体ID
   * @param {number} Opinion
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  verify_resource_data_recommandation(Record_Id, Entity_Id, Opinion = 1) {
    return instance.post(endpoint, {
      action: "verify_resource",
      subaction: "data_recommandation",
      Record_Id,
      Entity_Id,
      Opinion,
    });
  },
  /**
   * 受理审批单（数据资源评估请求）
   * @param {number} Record_Id
   * @param {number} Entity_Id 主体ID
   * @param {number} Opinion
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  verify_resource_data_verification(Record_Id, Entity_Id, Opinion) {
    return instance.post(endpoint, {
      action: "verify_resource",
      subaction: "data_verification",
      Record_Id,
      Entity_Id,
      Opinion,
    });
  },
  /**
   * 受理审批单（数据产品评估请求）
   * @param {number} Record_Id
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  verify_resource_product_verification(Record_Id, Entity_Id) {
    return instance.post(endpoint, {
      action: "verify_resource",
      subaction: "product_verification",
      Record_Id,
      Entity_Id,
      Opinion: 1,
    });
  },
  /**
   * 产品设计
   * @param {number} Entity_Id 主体ID
   * @param {string} Name 沙盘名称
   * @param {number} Price 产品价格
   * @param {string} Developer 开发者
   * @param {string} Material 产品材料
   * @param {string} Contribution 产品贡献
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  product_design(Entity_Id, Name, Price, Developer, Material, Contribution) {
    return instance.post(endpoint, {
      action: "product_design",
      Entity_Id,
      Name,
      Price,
      Developer,
      Material,
      Contribution,
    });
  },
  /**
   * 产品开发 （开发表单查询）
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  query_request_product_development(Entity_Id) {
    return instance.post(endpoint, {
      action: "query_request",
      subaction: "product_development",
      Entity_Id,
    });
  },
  /**
   * 产品开发 （产品加工）
   * @param {number} Record_Id
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  product_develope(Record_Id, Entity_Id, params = {}) {
    return instance.post(endpoint, {
      action: "product_develope",
      Record_Id,
      Entity_Id,
      ...params,
    });
  },
  /**
   * 产品上架
   * @param {number} Entity_Id 主体ID
   * @param {number} Record_Id 记录ID
   * @param {number} Verified_By 提交审核的第三方机构ID
   * @param {number} Trade_Center 交易所ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  product_launch(Entity_Id, Record_Id, Verified_By, Trade_Center) {
    return instance.post(endpoint, {
      action: "product_launch",
      Entity_Id,
      Record_Id,
      Verified_By,
      Trade_Center,
    });
  },
  /**
   * 产品购买
   * @param {number} Product_Id 产品ID
   * @param {number} Amount 购买价格
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  product_purchase(Product_Id, Amount, Entity_Id) {
    return instance.post(endpoint, {
      action: "product_purchase",
      Product_Id,
      Amount,
      Entity_Id,
    });
  },
  /**
   * 订单查询 （发起的订单）
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  contract_management_view_contract_sent(Entity_Id) {
    return instance.post(endpoint, {
      action: "contract_management",
      subaction: "view_contract_sent",
      Entity_Id,
    });
  },
  /**
   * 产品查询
   * @param {number} Entity_Id 主体ID
   * @param {number} Trade_Center 交易所ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  view_resource_view_products_posted(Entity_Id, Trade_Center) {
    return instance.post(endpoint, {
      action: "view_resource",
      subaction: "view_products_posted",
      Entity_Id,
      Trade_Center,
    });
  },
  /**
   * 订单查询 （收到的订单）
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  contract_management_view_contract_received(Entity_Id) {
    return instance.post(endpoint, {
      action: "contract_management",
      subaction: "view_contract_received",
      Entity_Id,
    });
  },
  /**
   * 订单受理
   * @param {number} Entity_Id 主体ID
   * @param {number} Contract_Id 订单ID
   * @param {number} Opinion 受理意见
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  contract_management_process_contract(Entity_Id, Contract_Id, Opinion) {
    return instance.post(endpoint, {
      action: "contract_management",
      subaction: "process_contract",
      Entity_Id,
      Contract_Id,
      Opinion,
    });
  },
  /**
   * 项目交付
   * @param {number} Project_Id 项目ID
   * @param {number} Product_Id 产品ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  finish_project(Project_Id, Product_Id) {
    return instance.post(endpoint, {
      action: "finish_project",
      Project_Id,
      Product_Id,
    });
  },
  /**
   * 查询已保荐企业
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  view_verified_entity(Entity_Id) {
    return instance.post(endpoint, {
      action: "view_verified_entity",
      Entity_Id,
    });
  },
  /**
   * 查询已推荐数据
   * @param {number} Entity_Id 主体ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  view_recommand_resource(Entity_Id) {
    return instance.post(endpoint, {
      action: "view_recommand_resource",
      Entity_Id,
    });
  },
};

export default control;
