<template>
  <LoginBackground>
    <SubTitle>数据流通交易模拟沙盘，快速帮助你理解数据交易全流程</SubTitle>
    <LoginForm></LoginForm>
  </LoginBackground>
</template>

<script>
import LoginBackground from "@/components/user/LoginBackground.vue";
import LoginForm from "@/components/user/LoginForm.vue";
import SubTitle from "@/components/user/SubTitle.vue";
export default {
  name: "LoginView",
  components: {
    LoginBackground,
    LoginForm,
    SubTitle,
  },
};
</script>

<style></style>
