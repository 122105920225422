/**
 * api接口的统一出口
 */
// 用户管理接口
import account from "@/api/usermanager/account";
// 沙盘环境功能接口
import financial from "@/api/ruleengine/financial";
// 沙盘管理
import room from "@/api/simroom/room";
// 沙盘资源接口
import resource from "@/api/common/resource";
// 沙盘角色接口
import control from "@/api/player/control";
// 导出接口
export default {
  account,
  financial,
  room,
  resource,
  control,
};
