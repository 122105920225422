/**
 * 用户管理接口 - account
 */
import instance from "@/utils/http"; // 导入http中创建的axios实例

const endpoint = "/usermanager/account/";

const account = {
  /**
   * 用户注册
   * @param {string} username 用户名
   * @param {string} password 密码
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  user_register(username, password) {
    return instance.post(endpoint, {
      action: "user_register",
      username,
      password,
    });
  },
  /**
   * 用户登录
   * @param {string} username 用户名
   * @param {string} password 密码
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  user_login(username, password) {
    return instance.post(endpoint, {
      action: "user_login",
      username,
      password,
    });
  },
  /**
   * 用户登出
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  user_logout() {
    return instance.post(endpoint, {
      action: "user_logout",
    });
  },
};

export default account;
