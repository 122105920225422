<template>
  <div
    class="GradientButton"
    :class="disabled ? 'disabled' : ''"
    @click="$emit('click')"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "GradientButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.GradientButton {
  width: 320px;
  line-height: 60px;
  border-radius: 30px;
  text-align: center;
  background: linear-gradient(270deg, #00b7ff 0%, #00f2ff 100%);
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  &.disabled {
    background: #717171 !important;
  }
}
</style>
