/**
 * 沙盘环境功能接口 - financial
 */
import instance from "@/utils/http"; // 导入http中创建的axios实例

const endpoint = "/simroom/room/";

const room = {
  /**
   * 创建沙盘（房间）
   * @param {string} Name 沙盘名称
   * @param {string} Created_By 创建人
   * @param {number} Expire_Date 结束时间
   * @param {number} Num_Enterprise_Max 企业
   * @param {number} Num_DataSaler_Max 数商
   * @param {number} Num_ThirdParty_Max 第三方
   * @param {number} Num_TradeCenter_Max 交易所
   * @param {number} Num_Supervisor_Max 监管部门
   * @param {number} Account_Balance 初始资金
   * @param {number} Movement_Point 初始行动点数
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  create_room(
    Name,
    Created_By,
    Expire_Date,
    Num_Enterprise_Max,
    Num_DataSaler_Max,
    Num_ThirdParty_Max,
    Num_TradeCenter_Max,
    Num_Supervisor_Max,
    Account_Balance,
    Movement_Point
  ) {
    return instance.post(endpoint, {
      action: "create_room",
      Name,
      Created_By,
      Expire_Date,
      Num_Enterprise_Max,
      Num_DataSaler_Max,
      Num_ThirdParty_Max,
      Num_TradeCenter_Max,
      Num_Supervisor_Max,
      Account_Balance,
      Movement_Point,
    });
  },
  /**
   * 列出已创建沙盘
   * @param {number} userid 用户ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  list_room(userid) {
    return instance.post(endpoint, {
      action: "list_room",
      User_Id: userid,
    });
  },
  /**
   * 关闭沙盘
   * @param {number} Room_Id 房间ID
   * @returns {Promise<AxiosResponse<any, any>>} Promise
   */
  close_room(Room_Id) {
    return instance.post(endpoint, {
      action: "close_room",
      Room_Id,
    });
  },
};

export default room;
