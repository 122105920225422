<template>
  <div class="SubTitle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SubTitle",
};
</script>

<style lang="scss" scoped>
.SubTitle {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 44px;
}
</style>
